import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_status = _resolveComponent("request-status")!
  const _component_SingleApplication = _resolveComponent("SingleApplication")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_status, {
      response: _ctx.requestResponse,
      isLoading: _ctx.loading
    }, null, 8, ["response", "isLoading"]),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_SingleApplication, {
          key: 0,
          isDoctor: true,
          singleApplication: _ctx.application,
          onActionTaken: _ctx.fetchApplication
        }, null, 8, ["singleApplication", "onActionTaken"]))
      : _createCommentVNode("", true)
  ], 64))
}