import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  align: "start",
  justify: "space-between",
  class: "flex mb-10"
}
const _hoisted_2 = { class: "flex-item" }
const _hoisted_3 = { class: "flex-item" }
const _hoisted_4 = {
  class: "ml-2",
  style: {"font-weight":"500"}
}
const _hoisted_5 = { class: "flex-item" }
const _hoisted_6 = {
  class: "ml-2",
  style: {"font-weight":"500"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!
  const _component_v_avatar = _resolveComponent("v-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          _createVNode(_component_v_card, _mergeProps({
            height: "520",
            class: "ml-5 mr-5 my-8",
            style: {"position":"relative"},
            elevation: isHovering ? 16 : 6
          }, props), {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                height: "200px",
                src: require('../../assets/doc-stud.jpg'),
                cover: ""
              }, null, 8, ["src"]),
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("internship")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_card_text, { class: "mt-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "blue",
                    icon: "mdi-check"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("different-specialties")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, { class: "mt-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "blue",
                    icon: "mdi-check"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("available-country")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, { class: "mt-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    color: "blue",
                    icon: "mdi-check"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("increasing-doctors")), 1)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1040, ["elevation"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          _createVNode(_component_v_card, _mergeProps({
            height: "520",
            class: "ml-5 mr-5 my-8",
            style: {"position":"relative"},
            elevation: isHovering ? 16 : 6
          }, props), {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                height: "200px",
                src: require('../../assets/doc-team.jpg'),
                cover: ""
              }, null, 8, ["src"]),
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("block-practicals")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("block-practicals-text")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, {
                class: "mt-10",
                style: {"position":"absolute","bottom":"0","left":"0","right":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-clock-time-eight")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("available-soon")), 1)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1040, ["elevation"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          _createVNode(_component_v_card, _mergeProps({
            height: "520",
            class: "ml-5 mr-5 my-8",
            variant: "elevated",
            style: {"position":"relative"},
            elevation: isHovering ? 16 : 6
          }, props), {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                height: "200px",
                src: require('../../assets/lmu.jpg'),
                cover: ""
              }, null, 8, ["src"]),
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("advance-options")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("further-qualifications")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, {
                class: "mt-10",
                style: {"position":"absolute","bottom":"0","left":"0","right":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_avatar, { color: "blue-darken-2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-clock-time-eight")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("available-soon")), 1)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1040, ["elevation"])
        ]),
        _: 1
      })
    ])
  ]))
}