<template>
  <HeaderComponent></HeaderComponent>
  <v-card
    class="mx-auto pa-12 pb-8 my-15"
    style="font-family: Verdana, Geneva, Tahoma, sans-serif"
    elevation="8"
    max-width="648"
    rounded="lg"
    ><div align="center">
      <v-card-title> {{ $t('email-verification-title') }} </v-card-title>
      <v-card-text>
        {{ $t('email-verification-text') }}
        <a
          class="text-blue text-decoration-none"
          rel="noopener noreferrer"
          style="cursor: pointer"
          @click="$router.push('/doc-login')"
        >
          {{ $t('login') }}
        </a>
      </v-card-text>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HeaderComponent from '../../../Header/HeaderComponent.vue'

export default defineComponent({
  components: {
    HeaderComponent,
  },
  name: 'EmailVerified',
})
</script>
