<template>
  <HeaderComponent></HeaderComponent>
  <request-status
    :response="doctorResponseStatus"
    :isLoading="isDoctorLoading"
  />
  <request-status
    :response="studentResponseStatus"
    :isLoading="isStudentLoading"
  />

  <div align="center" v-if="!isDoctorLoading && doctor && !isStudentLoading">
    <v-col :cols="smallWindow ? 12 : 8">
      <v-card>
        <v-row class="mt-10" justify="space-between">
          <v-col class="mt-1" :class="!smallWindow ? 'ml-5' : undefined">
            <v-card-title class="custom-title">{{ doctorName }}</v-card-title>

            <p align="center">{{ doctorSpecialty }}</p>
            <v-card-subtitle class="mt-2">
              <v-icon icon="mdi-map-marker"></v-icon>
              {{ doctorAddress }}</v-card-subtitle
            >

            <v-card-subtitle>
              <v-img
                width="30"
                style="border-radius: 10%"
                :src="doctor.universityLogo"
              ></v-img
              >{{ doctor.university }}</v-card-subtitle
            >
            <div align="center" justify="space-between">
              <div style="width: 85%">
                <VueDatePicker
                  class="mt-3 mb-2"
                  :enable-time-picker="false"
                  v-model="startDate"
                  format="dd MMM yyyy"
                  density="compact"
                  variant="outlined"
                  :clearable="false"
                  :label="$t('you-apply')"
                  :min-date="today"
                  @change="handleStartDateChange"
                  :placeholder="$t('search.date-line')"
                />
              </div>
              <v-progress-circular
                v-if="isNewDateLoading"
                color="green"
                indeterminate
              ></v-progress-circular>
              <v-alert
                v-if="
                  !isDoctorAvailable &&
                  startDate !== null &&
                  !isNewDateLoading &&
                  !isDoctorLoading &&
                  !isStudentLoading
                "
                class="ml-6 mr-6"
                color="#C51162"
                icon="mdi-cancel"
                theme="dark"
                border
              >
                {{ $t('no-availability') }}
              </v-alert>
            </div>
          </v-col>
          <v-col>
            <v-img
              width="150"
              style="border-radius: 50%"
              :src="doctor.photo"
            ></v-img>
          </v-col>
        </v-row>

        <GeneralInformation
          :generalInfo="student"
          :isApplying="true"
          :startDate="startDate"
          :endDate="endDate"
          :hasAlreadyApplied="doctor.applied"
          :isDoctorAvailable="isDoctorAvailable"
          :doctorId="id"
        ></GeneralInformation>
      </v-card>
    </v-col>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, defineProps, onBeforeUnmount, ref, watch } from 'vue'
import { DoctorProfile, SearchParams, Student } from '@/types'
import HeaderComponent from '../Header/HeaderComponent.vue'
import router from '@/router'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useUserActions } from '@/stores/user-actions'
import moment from 'moment'
import GeneralInformation from '../StudentProfile/GeneralInformation/GeneralInformation.vue'
import FooterComponent from '../Footer/FooterComponent.vue'

const userAction = useUserActions()
const { t } = useI18n()
const route = useRoute()
const id = ref()
const startDate = ref<Date | null>(null)
const endDate = ref<Date | null>(null)
const today = ref(new Date())
const token = ref<string | null>(null)
const doctorResponseStatus = ref({})
const studentResponseStatus = ref({})
const student = ref<Student>()
const isStudentLoading = ref(true)
const isDoctorLoading = ref(true)
const isDoctorAvailable = ref(false)
const isNewDateLoading = ref(false)
const savedSearchParams = ref<SearchParams>()
const doctor = ref<DoctorProfile | undefined>(undefined)
const smallWindow = ref(window.innerWidth < 1000)

onMounted(() => {
  id.value = route.params.id

  token.value = localStorage.getItem('accessToken') ?? null
  if (token.value) {
    localStorage.removeItem('docId')
    getDoctorProfile()
    getStudentProfile()
  } else {
    router.push('/login')
  }

  const storedSearchParams = localStorage.getItem('searchParams')
  if (storedSearchParams) {
    savedSearchParams.value = JSON.parse(storedSearchParams)
    startDate.value = savedSearchParams?.value?.startDate
      ? moment(savedSearchParams?.value?.startDate).locale('de').toDate()
      : null

    endDate.value = savedSearchParams?.value?.endDate
      ? moment(savedSearchParams?.value?.endDate).locale('de').toDate()
      : null
  }
})

const updateWindowSize = (): void => {
  smallWindow.value = window.innerWidth < 1000
}

onMounted(() => {
  window.addEventListener('resize', updateWindowSize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize)
})

const doctorName = computed(() => {
  return doctor.value
    ? `${doctor.value.title} ${doctor.value.firstName} ${
        doctor.value.lastName || ''
      }`
    : ''
})
const doctorSpecialty = computed(() => {
  return doctor.value?.specialty ?? ''
})

const doctorAddress = computed(() => {
  return `${doctor.value?.clinicStreet}, ${doctor.value?.clinicLocation}, ${doctor.value?.clinicLand}`
})

async function handleStartDateChange(newDate: Date | null): Promise<void> {
  isNewDateLoading.value = true

  if (newDate) {
    startDate.value = newDate
    endDate.value = new Date(startDate.value)
    endDate.value.setDate(endDate.value.getDate() + 30)
    const response = await userAction.isDoctorAvailable(
      id.value,
      userAction.getDatesInString(startDate.value),
      userAction.getDatesInString(endDate.value)
    )
    isDoctorAvailable.value = response.result?.isAvailable as boolean

    isNewDateLoading.value = false
  }
}

watch(startDate, handleStartDateChange)

const getDoctorProfile = async (): Promise<void> => {
  const response = await userAction.getSingleDoctor(id.value, {})
  doctorResponseStatus.value = response
  if (response.success) {
    doctor.value = response.result as DoctorProfile
  }
  isDoctorLoading.value = false
}

const getStudentProfile = async () => {
  isStudentLoading.value = true
  if (token.value == null) {
    router.push('/login')
  } else {
    const response = await userAction.getStudentProfile(token.value)
    studentResponseStatus.value = response
    if (response.success) {
      student.value = response.result as Student
    }
    isStudentLoading.value = false
  }
}
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
