import { SearchResponseData } from '../types/index'
import { defineStore } from 'pinia'
import photonClient from './photonClient'

export const commonStore = defineStore({
  id: 'common',

  state: () => ({
    id: '',
  }),

  actions: {
    async searchLocation(keyword: string): Promise<SearchResponseData> {
      let data: SearchResponseData = { features: [], type: '' }

      await photonClient
        .get(`/`, {
          params: {
            q: keyword,
            lang: localStorage.getItem('locale') ?? 'de',
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
