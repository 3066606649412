import { createApp } from 'vue'
import './styles/Style.css'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import de from './locales/de.json'
import { createPinia } from 'pinia'
import RequestStatus from './components/Static/RequestStatus/RequestStatus.vue'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import { createMetaManager } from 'vue-meta'

loadFonts()

const pinia = createPinia()

export const i18n = createI18n({
  legacy: false,
  locale: 'de',
  globalInjection: true,
  messages: {
    en: en,
    de: de,
  },
})
const app = createApp(App)
app.component('request-status', RequestStatus)
app.component('VueDatePicker', VueDatePicker)
app.component('QuillEditor', QuillEditor)

app
  .use(ToastPlugin)
  .use(i18n)
  .use(pinia)
  .use(router)
  .use(createMetaManager())
  .use(vuetify)
  .mount('#app')
