<template>
  <h1 class="mt-10" align="center">{{ $t('our-doctors') }}</h1>
  <v-row class="my-8" justify="center" align="center">
    <v-card
      class="mx-10 my-5"
      width="500"
      title="Dr. Philipp Gross"
      subtitle="Facharzt/-in für Allgemeinmedizin"
      @click="$router.push('/doctors/655f03b38351b69e9e23c461')"
    >
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2">
          <v-icon icon="mdi-shield-check-outline"></v-icon>
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-avatar size="100">
          <v-img
            src="https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/01/13/1705173797902.blob"
            alt="Philipp Gross"
          ></v-img>
        </v-avatar>
      </template>
      <v-card-text class="ml-2">
        <v-icon>mdi-map-marker</v-icon> München, Germany</v-card-text
      >
    </v-card>
    <v-card
      class="mx-10 my-5"
      width="500"
      title="Dr. Tobias Weber"
      subtitle="Facharzt/-in für Allgemeinmedizin"
      @click="$router.push('/doctors/6569891578adb328249cecd6')"
    >
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2">
          <v-icon icon="mdi-shield-check-outline"></v-icon>
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-avatar size="100">
          <v-img
            src="https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2023/12/20/1703085778619.blob"
            alt="Tobias Weber"
          ></v-img>
        </v-avatar>
      </template>
      <v-card-text class="ml-2"
        ><v-icon>mdi-map-marker</v-icon> München, Germany</v-card-text
      >
    </v-card>
    <v-card
      class="mx-10 my-5"
      width="500"
      title="Dr. Monika Schmidmayr "
      subtitle="Facharzt/-in für Gynäkologie"
      @click="$router.push('/doctors/656645ad6600fbcdcc56ba57')"
    >
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2">
          <v-icon icon="mdi-shield-check-outline"></v-icon>
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-avatar size="100">
          <v-img
            src="https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/03/07/1709848427785.blob"
            alt="Monika Schmidmayr"
          ></v-img>
        </v-avatar>
      </template>
      <v-card-text class="ml-2"
        ><v-icon>mdi-map-marker</v-icon> Wolnzach, Germany</v-card-text
      >
    </v-card>

    <v-card
      class="mx-10 my-5"
      width="500"
      title="Dr. Ursula Glombik-Lukassek  "
      subtitle="Facharzt/-in für Allgemeinmedizin"
      @click="$router.push('/doctors/6565d8d67ad7aca7836f8086')"
    >
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2">
          <v-icon icon="mdi-shield-check-outline"></v-icon>
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-avatar size="100">
          <v-img
            src="https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/03/07/1709848647200.blob"
            alt="Ursula Glombik-Lukassek"
          ></v-img>
        </v-avatar>
      </template>
      <v-card-text class="ml-2"
        ><v-icon>mdi-map-marker</v-icon> Nördlingen , Germany</v-card-text
      >
    </v-card>

    <v-card
      class="mx-10 my-5"
      width="500"
      title="Dr. Ute Neidlinger  "
      subtitle="Facharzt/-in für Allgemeinmedizin"
      @click="$router.push('/doctors/6565ecec7ad7aca7836f8088')"
    >
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2">
          <v-icon icon="mdi-shield-check-outline"></v-icon>
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-avatar size="100">
          <v-img
            src="https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2023/11/28/1701179297801.JPG"
            alt="Ute Neidlinger"
          ></v-img>
        </v-avatar>
      </template>
      <v-card-text class="ml-2"
        ><v-icon>mdi-map-marker</v-icon> Hebertsfelden, Germany</v-card-text
      >
    </v-card>
    <!-- <v-card
      class="mx-10 my-5"
      width="500"
      title="Frau Susanne Römer  "
      subtitle="Facharzt/-in für Innere und Allgemeinmedizin"
      @click="$router.push('/doctors/65e0691ff21af3c68d760529')"
    >
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2">
          <v-icon icon="mdi-shield-check-outline"></v-icon>
        </v-avatar>
      </template>
      <template v-slot:append>
        <v-avatar size="100">
          <v-img
            src="https://s3.eu-central-1.amazonaws.com/docforme.eu/publicDocs/2024/04/22/1713807551420.blob"
            alt="Susanne Römer"
          ></v-img>
        </v-avatar>
      </template>
      <v-card-text class="ml-2"
        ><v-icon>mdi-map-marker</v-icon> Freising, Germany</v-card-text
      >
    </v-card> -->
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TopDoctorsComponent',

  data() {
    return {}
  },
})
</script>
