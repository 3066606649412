import { defineStore } from 'pinia'
import apiClient from './apiclients'
import { RequestResponse, SearchParams, Student } from '../types/index'
import { Delta } from '@vueup/vue-quill'
export const broadcastingActions = defineStore({
  id: 'broadcasting-actions',
  state: () => ({}),

  actions: {
    async subscribeToDoctor(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .post(
          'connection',
          { doctorId: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async unSubscribeToDoctor(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .get(`connection/take-back/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async getSubscribers(
      page: number,
      itemsPerPage: number
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem('docAccessToken') ?? ''

      await apiClient
        .post(
          'get-connections',
          {
            page: page,
            pageSize: itemsPerPage,
            role: '',
            status: 'CREATED',
            name: '',
            search: '',
          },
          {
            headers: {
              Authorization: `Bearer ${docToken}`,
            },
          }
        )
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async removeSubscriber(requestBody: {
      connectionId: string
      status: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem('docAccessToken') ?? ''

      const headers = {
        Authorization: `Bearer ${docToken}`,
        'Content-Type': 'application/json',
      }
      apiClient
        .put('update-connection-status', requestBody, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async broadcastMessage(messageBody: {
      title: string
      content: Delta | undefined
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: {} }
      const docToken = localStorage.getItem('docAccessToken') ?? ''
      const headers = {
        Authorization: `Bearer ${docToken}`,
      }
      await apiClient
        .post('message', messageBody, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async getMessages(
      page: number,
      itemsPerPage: number,
      token: string
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }

      await apiClient
        .post(
          'get-messages',
          {
            page: page,
            pageSize: itemsPerPage,
            role: '',
            name: '',
            search: '',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async updateMessage(
      messageBody: {
        title: string
        content: Delta | undefined
      },
      id: string
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const docToken = localStorage.getItem('docAccessToken') ?? ''

      const headers = {
        Authorization: `Bearer ${docToken}`,
        'Content-Type': 'application/json',
      }
      await apiClient
        .put(`messages/${id}`, messageBody, { headers })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async checkSubscription(
      token: string,
      id: string
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }

      await apiClient
        .get(`check-connect/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async markSeen(token: string, messageId: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }

      await apiClient
        .get(`messages/${messageId}/seen`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async getNotifications(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .get(`my-messages-count`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
  },
})
