<template>
  <div align="start" justify="space-between" class="flex mb-10">
    <div class="flex-item">
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-card
            height="520"
            class="ml-5 mr-5 my-8"
            style="position: relative"
            :elevation="isHovering ? 16 : 6"
            v-bind="props"
          >
            <v-img
              height="200px"
              :src="require('../../assets/doc-stud.jpg')"
              cover
            ></v-img>

            <v-card-title> {{ t("internship") }} </v-card-title>

            <v-spacer></v-spacer>

            <v-card-text class="mt-2">
              <v-icon color="blue" icon="mdi-check"></v-icon>
              {{ t("different-specialties") }}</v-card-text
            >
            <v-card-text class="mt-2">
              <v-icon color="blue" icon="mdi-check"></v-icon>
              {{ t("available-country") }}</v-card-text
            >
            <v-card-text class="mt-2">
              <v-icon color="blue" icon="mdi-check"></v-icon>
              {{ t("increasing-doctors") }}</v-card-text
            >

            <!-- <v-card-actions
                style="position: absolute; bottom: 0; left: 0; right: 0"
              >
                <v-btn color="orange-lighten-2" variant="text"> Weiter </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions> -->
          </v-card>
        </template>
      </v-hover>
    </div>
    <div class="flex-item">
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-card
            height="520"
            class="ml-5 mr-5 my-8"
            style="position: relative"
            :elevation="isHovering ? 16 : 6"
            v-bind="props"
          >
            <v-img
              height="200px"
              :src="require('../../assets/doc-team.jpg')"
              cover
            ></v-img>

            <v-card-title> {{ t("block-practicals") }} </v-card-title>

            <v-card-text>
              {{ t("block-practicals-text") }}
            </v-card-text>
            <v-card-text
              class="mt-10"
              style="position: absolute; bottom: 0; left: 0; right: 0"
            >
              <v-avatar color="blue-darken-2">
                <v-icon>mdi-clock-time-eight</v-icon>
              </v-avatar>
              <span class="ml-2" style="font-weight: 500">
                {{ t("available-soon") }}
              </span>
            </v-card-text>
            <!-- <v-card-actions
                style="position: absolute; bottom: 0; left: 0; right: 0"
              >
                <v-btn color="orange-lighten-2" variant="text"> Weiter </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions> -->
          </v-card>
        </template>
      </v-hover>
    </div>
    <!-- <div class="flex-item">
      <div class="mt-5">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              height="520"
              class="ml-5 mr-5 my-8"
              variant="elevated"
              style="position: relative"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-img
                height="200px"
                src="https://cms-cdn.lmu.de/media/lmu-mediapool/die_lmu/lmu_hauptgebauede_gsp_id5708_full_2_1_format_m.jpg"
                cover
              ></v-img>

              <v-card-title> {{ t('practical-year') }} </v-card-title>
              <v-card-text> {{ t('practical-year-text') }}</v-card-text>
              <v-card-text
                class="mt-10"
                style="position: absolute; bottom: 0; left: 0; right: 0"
              >
                <v-avatar color="blue-darken-2">
                  <v-icon>mdi-account-hard-hat</v-icon>
                </v-avatar>
                <span class="ml-2" style="font-weight: 500">
                  {{ t('available-soon') }}
                </span>
              </v-card-text>
             
            </v-card>
          </template>
        </v-hover>
      </div>
    </div> -->
    <div class="flex-item">
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-card
            height="520"
            class="ml-5 mr-5 my-8"
            variant="elevated"
            style="position: relative"
            :elevation="isHovering ? 16 : 6"
            v-bind="props"
          >
            <v-img
              height="200px"
              :src="require('../../assets/lmu.jpg')"
              cover
            ></v-img>

            <v-card-title> {{ t("advance-options") }} </v-card-title>

            <v-card-text>
              {{ t("further-qualifications") }}
            </v-card-text>
            <v-card-text
              class="mt-10"
              style="position: absolute; bottom: 0; left: 0; right: 0"
            >
              <v-avatar color="blue-darken-2">
                <v-icon>mdi-clock-time-eight</v-icon>
              </v-avatar>
              <span class="ml-2" style="font-weight: 500">
                {{ t("available-soon") }}
              </span>
            </v-card-text>

            <!-- <v-card-actions
                style="position: absolute; bottom: 0; left: 0; right: 0"
              >
                <v-btn color="orange-lighten-2" variant="text"> Weiter </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions> -->
          </v-card>
        </template>
      </v-hover>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"

export default defineComponent({
  name: "AdvantageDisadvantageComponent",

  data() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<style>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 20%; /* Adjust the width as needed */
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
