<template>
  <div class="mt-10">
    <v-divider></v-divider>
    <div class="footer-container">
      <div>
        <v-btn
          rounded="sm"
          variant="text"
          class="mr-2"
          @click="$router.push('/t-and-c')"
        >
          {{ $t('footer.terms') }}
        </v-btn>
        <v-btn
          rounded="sm"
          variant="text"
          class="mr-2"
          @click="$router.push('/contact')"
        >
          {{ $t('footer.contact') }}
        </v-btn>
        <v-btn
          rounded="sm"
          variant="text"
          class="mr-2"
          @click="$router.push('/imprint')"
        >
          {{ $t('footer.imprint') }}
        </v-btn>
        <v-btn
          rounded="sm"
          variant="text"
          class="mr-2"
          @click="$router.push('/privacy-policy')"
        >
          {{ $t('footer.privacy-policy') }}
        </v-btn>
        <v-btn
          rounded="sm"
          variant="text"
          class="mr-2"
          @click="$router.push('/attribute')"
        >
          {{ $t('footer.attribute') }}
        </v-btn>
      </div>
      <div class="mt-4">
        <!-- <v-btn
          class="mr-2"
          icon="mdi-instagram"
          size="small"
          href="https://www.instagram.com/docforme.eu/"
          target="_blank"
        ></v-btn> -->
        <v-btn
          icon="mdi-linkedin"
          size="small"
          href="https://www.linkedin.com/company/medcircle-eu/"
          target="_blank"
        ></v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'FooterComponent',

  data() {
    const { t } = useI18n()
    return
  },
})
</script>

<style>
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 16px;
}
</style>
