<template>
  <request-status :response="requestResponse" />

  <v-data-table
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="applicantsArray"
    :loading="loading"
  >
    <template v-slot:[`item.photo`]="props">
      <v-img
        v-if="props.item.photo"
        v-bind="props"
        :src="props.item.photo"
        alt="student Image"
        class="image mb-2"
        referrerpolicy="no-referrer"
        rel="noreferrer"
      />
      <div v-else>
        <v-img
          v-bind="props"
          :src="require('../../../../../assets/stud-team1.png')"
          alt="student Image"
          class="image mb-2"
          referrerpolicy="no-referrer"
          rel="noreferrer"
        />
      </div>
    </template>

    <template v-slot:[`item.action`]="props">
      <v-btn
        v-bind="props"
        color="rgb(172, 36, 127)"
        @click="
          router.push({
            path: `/doctor-dashboard/application/${props.item.id}`,
          })
        "
      >
        {{ $t('table.see-applications') }}
      </v-btn>
    </template></v-data-table
  >
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps, ref, onMounted, computed, Ref } from 'vue'
import { Applicant } from '@/types'
import { doctorActions } from '@/stores/doctor-actions'
import { FormatDateFunction } from '@/types/spec'
import router from '@/router'
const props = defineProps<{ tab: string }>()
const applicants = ref<Applicant[]>([])

const tab = ref(props.tab)
const loading = ref(false)
const { t } = useI18n()
const docAction = doctorActions()

const page = ref(1)
const itemsPerPage = ref(20)

const requestResponse = ref()
const headers = computed(() => {
  return [
    {
      title: '',

      key: 'photo',
      sortable: false,
      align: 'center',
    },
    { title: t('table.name'), key: 'name', sortable: false, align: 'center' },
    { title: t('table.uni'), key: 'uni', sortable: false, align: 'center' },
    {
      title: t('table.start-date'),
      key: 'startDate',
      sortable: true,
      align: 'center',
    },

    {
      title: t('table.end-date'),
      key: 'endDate',
      sortable: true,
      align: 'center',
    },
    {
      title: t('table.action'),
      key: 'action',
      sortable: false,
      align: 'center',
    },
  ]
})

onMounted(() => {
  loadApplications()
})

const loadApplications = async () => {
  loading.value = true
  const response = await docAction.getApplications(
    tab.value,
    page.value,
    itemsPerPage.value
  )
  if (response.success) {
    applicants.value = response.result as Applicant[]
  }
  requestResponse.value = response
  loading.value = false
}

const applicantsArray = computed((): any => {
  const array: any[] = []

  applicants.value.forEach((applicant) => {
    const singleApplicant = {
      id: applicant._id,
      photo: applicant.profileUrl,
      name: applicant.firstName + ' ' + applicant.lastName,
      uni: applicant.university,
      startDate: FormatDateFunction(applicant.startDate),
      endDate: FormatDateFunction(applicant.endDate),
    }
    array.push(singleApplicant)
  })

  return array
})
</script>

<style scoped>
.custom-title {
  font-size: x-large;
}

.custom-subtitle {
  font-size: large;
}
.image {
  width: 50px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 10px;
  height: 50px;
  max-width: 50px;
  min-height: 50px;
}
</style>
