import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoctorHeader = _resolveComponent("DoctorHeader")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DoctorHeader),
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_layout, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_navigation_drawer, {
              "expand-on-hover": _ctx.smallWindow,
              rail: _ctx.smallWindow,
              permanent: !_ctx.smallWindow,
              "mobile-breakpoint": "xs",
              width: "230",
              height: "100vh"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      "prepend-avatar": 
              _ctx.docAction.doctorProfileLink !== ''
                ? _ctx.docAction.doctorProfileLink
                : require('../../../assets/blank.png')
            ,
                      title: _ctx.name
                    }, null, 8, ["prepend-avatar", "title"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider),
                _createVNode(_component_v_list, {
                  density: "compact",
                  nav: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      "prepend-icon": "mdi-account",
                      title: _ctx.$t('doctor.overview'),
                      class: _normalizeClass({
              'v-list-item--active': _ctx.selectedItem === 'Doctor Overview',
              'v-list-item--inactive': _ctx.selectedItem !== 'Doctor Overview',
            }),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo('Doctor Overview')))
                    }, null, 8, ["title", "class"]),
                    _createVNode(_component_v_list_item, {
                      "prepend-icon": "mdi-radio-tower",
                      title: "Broadcast",
                      stacked: "",
                      class: _normalizeClass({
              'v-list-item--active': _ctx.selectedItem === 'Broadcast',
              'v-list-item--inactive': _ctx.selectedItem !== 'Broadcast',
            }),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateTo('Broadcast')))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_v_badge, {
                          color: "blue",
                          content: _ctx.$t('new'),
                          inline: ""
                        }, null, 8, ["content"])
                      ]),
                      _: 1
                    }, 8, ["class"]),
                    _createVNode(_component_v_list_item, {
                      "prepend-icon": "mdi-account-group",
                      title: _ctx.$t('your-circle'),
                      stacked: "",
                      class: _normalizeClass({
              'v-list-item--active': _ctx.selectedItem === 'Subscribers',
              'v-list-item--inactive': _ctx.selectedItem !== 'Subscribers',
            }),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigateTo('Subscribers')))
                    }, {
                      append: _withCtx(() => [
                        _createVNode(_component_v_badge, {
                          color: "blue",
                          content: _ctx.$t('new'),
                          inline: ""
                        }, null, 8, ["content"])
                      ]),
                      _: 1
                    }, 8, ["title", "class"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["expand-on-hover", "rail", "permanent"]),
            _createVNode(_component_v_main, { style: {"min-height":"100vh"} }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_FooterComponent)
  ], 64))
}