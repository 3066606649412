<template>
  <HeaderComponent></HeaderComponent>
  <request-status :response="responseStatus" :isLoading="isLoading" />
  <div align="center" justify="space-between">
    <div class="container" v-if="isLoading">
      <v-progress-circular indeterminate color="green" />
    </div>
    <div v-if="!isLoading">
      <search-component @search-clicked="onSearching"></search-component>

      <v-col v-if="!isLoading">
        <div
          class="doctor-card-container"
          v-for="doctor in doctors"
          :key="doctor._id"
        >
          <doctor-card :doctor="doctor" />
        </div>
        <div v-if="doctors?.length == 0">
          <v-card
            class="mx-auto"
            height="400"
            :image="require('../../assets/drake.jpeg')"
            max-width="500"
            theme="dark"
            :title="$t('no-doctors')"
          >
            <v-card-text style="font-weight: bold">
              {{ $t('no-doctors-text') }}
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </div>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useUserActions } from './../../stores/user-actions'
import { useI18n } from 'vue-i18n'
import HeaderComponent from '../Header/HeaderComponent.vue'
import { Doctors, SearchParams } from '../../types/index'
import DoctorCard from '../Static/DoctorCard/DoctorCard.vue'
import SearchComponent from '../Search/SearchComponent.vue'
import router from '@/router'
import FooterComponent from '../Footer/FooterComponent.vue'
import { useMeta } from 'vue-meta'

const { t } = useI18n()

const doctors = ref<Doctors | undefined>(undefined)
const isLoading = ref<boolean>(false)

const responseStatus = ref({})
const savedSearchParams = ref<SearchParams>()

onMounted(() => {
  onSearching()
})

useMeta(
  computed(() => ({
    title: t('landing-title'),
  }))
)

const onSearching = async (): Promise<void> => {
  const storedSearchParams = localStorage.getItem('searchParams')
  if (storedSearchParams) {
    savedSearchParams.value = JSON.parse(storedSearchParams)

    const userAction = useUserActions()
    isLoading.value = true

    const searchParams: SearchParams = {
      startDate: savedSearchParams.value?.startDate ?? '',
      endDate: savedSearchParams.value?.startDate ?? '',
      latitude: savedSearchParams.value?.latitude ?? 0,
      longitude: savedSearchParams.value?.longitude ?? 0,
      specialty: savedSearchParams.value?.specialty ?? '',
    }

    const response = await userAction.searchForDoctor(searchParams)
    responseStatus.value = response
    if (response.success) {
      doctors.value = response.result as Doctors
    }
    isLoading.value = false
  } else {
    router.push('/')
  }
}
</script>
<style>
.doctor-card-container {
  display: inline-block;
}
</style>
