import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex mt-10" }
const _hoisted_2 = { class: "flex-item" }
const _hoisted_3 = { class: "flex-item" }
const _hoisted_4 = { class: "font-weight-regular ml-4 mt-2 mb-2" }
const _hoisted_5 = { class: "flex-item" }
const _hoisted_6 = { align: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_menu, {
            modelValue: _ctx.fromDateMenu,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fromDateMenu) = $event)),
            "close-on-content-click": false
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_text_field, _mergeProps({
                class: "ml-4 mr-4",
                modelValue: _ctx.formattedDate,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formattedDate) = $event)),
                "prepend-inner-icon": "mdi-calendar",
                label: _ctx.$t('search.date-line'),
                style: {"min-width":"250px"}
              }, props), null, 16, ["modelValue", "label"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_VueDatePicker, {
                inline: _ctx.isInline,
                "enable-time-picker": false,
                modelValue: _ctx.selectedDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDate) = $event)),
                "min-date": new Date(),
                format: "dd MMM yyyy",
                class: "v-theme--light mb-2",
                placeholder: _ctx.$t('search.date-line'),
                onDateUpdate: _ctx.handleDate
              }, null, 8, ["inline", "modelValue", "min-date", "placeholder", "onDateUpdate"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_autocomplete, {
            class: "ml-4 mr-4",
            "no-filter": "",
            "prepend-inner-icon": "mdi-map-marker",
            modelValue: _ctx.chosenAddress,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.chosenAddress) = $event)),
            items: _ctx.addressOptions,
            "item-title": _ctx.getCityAddress,
            "item-value": _ctx.getCityAddressValue,
            label: _ctx.$t('search.city'),
            onInput: _ctx.emitCityInput,
            style: {"height":"80px","min-width":"250px"}
          }, {
            item: _withCtx(({ props, item }) => [
              (!_ctx.cityLoading)
                ? (_openBlock(), _createBlock(_component_v_list_item, _normalizeProps(_mergeProps({ key: 0 }, props)), {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.name), 1)
                    ]),
                    subtitle: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.raw.country), 1)
                    ]),
                    _: 2
                  }, 1040))
                : _createCommentVNode("", true)
            ]),
            "no-data": _withCtx(() => [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('no-data')), 1)
            ]),
            "append-inner": _withCtx(() => [
              (_ctx.cityLoading)
                ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                    key: 0,
                    indeterminate: "",
                    class: "ml-2"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "items", "item-title", "item-value", "label", "onInput"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_autocomplete, {
            class: "ml-4 mr-4",
            "prepend-inner-icon": "mdi-doctor",
            modelValue: _ctx.specialty,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.specialty) = $event)),
            items: 
            _ctx.locale == 'de' ? _ctx.SPECIALIZATION_DATA_DE : _ctx.SPECIALIZATION_DATA_EN
          ,
            label: _ctx.$t('search.specialty'),
            outlined: "",
            "menu-props": { width: '700px' },
            style: {"min-width":"250px"}
          }, null, 8, ["modelValue", "items", "label"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_v_btn, {
            disabled: !_ctx.infoCompleted,
            onClick: _ctx.onSearchClicked,
            class: "ml-5",
            icon: "mdi-magnify",
            color: "#3fb984",
            size: "large"
          }, null, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}