<template>
  <div class="app-bar">
    <router-link class="logo-container" to="/" custom>
      <button v-on:click="onLogoClicked()">
        <div class="logo-wrapper">
          <img
            :src="require('../../assets/441.png')"
            alt="medcircle"
            class="logo"
            style="border-radius: 40%; border-radius: 8px; margin-right: 4px"
          />
          <div class="text-logo">edcircle</div>
        </div>
      </button>
    </router-link>

    <div v-if="!smallWindow" class="btns-container d-flex gap">
      <locale-picker />
      <template v-for="item in headerProps" :key="item.text">
        <router-link v-if="item.path" :to="item.path">
          <v-btn class="text-none text-subtitle-1" variant="text" color="white">
            {{ item.text }}
          </v-btn>
        </router-link>

        <v-menu
          v-if="item.subitems"
          offset-y
          :open-on-hover="isTouchScreen ? false : true"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              class="text-none text-subtitle-1"
              variant="text"
              color="white"
              v-bind="props"
            >
              {{ item.text }}
              <v-img
                v-if="item.photo"
                :src="item.photo"
                :width="30"
                style="border-radius: 50%"
              ></v-img>
              <v-icon v-else right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list style="background-color: #1e293c">
            <v-list-item
              v-for="subitem in item.subitems"
              :key="subitem.text"
              :to="subitem.path"
            >
              <v-icon color="white" right>{{ subitem.icon }}</v-icon>
              <v-btn
                class="text-subtitle-1"
                variant="text"
                color="white"
                style="min-width: 120px"
                >{{ subitem.text }}</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </div>
    <div v-else class="btns-container">
      <locale-picker />
      <v-menu
        offset-y
        :close-on-content-click="true"
        close
        eager
        :open-on-hover="isTouchScreen ? false : true"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            class="text-none text-subtitle-1"
            variant="text"
            color="white"
            v-bind="props"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list style="background-color: #1e293c">
          <template v-for="item in headerProps" :key="item.text">
            <v-list-item>
              <v-btn
                v-if="item.path"
                :to="item.path"
                class="text-none text-subtitle-1 ml-2"
                variant="text"
                color="white"
                style="min-width: 140px"
              >
                {{ item.text }}
              </v-btn>

              <v-menu
                v-if="item.subitems"
                offset-y
                open-on-click
                eager
                :open-on-hover="isTouchScreen ? false : true"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    class="text-none text-subtitle-1 ml-2"
                    variant="text"
                    color="white"
                    style="min-width: 140px"
                    v-bind="props"
                  >
                    {{ item.text }}
                    <v-img
                      v-if="item.photo"
                      :src="item.photo"
                      :width="30"
                      style="border-radius: 50%"
                    ></v-img>
                    <v-icon v-else right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list style="background-color: #1e293c">
                  <v-list-item
                    v-for="subitem in item.subitems"
                    :key="subitem.text"
                    :to="subitem.path"
                  >
                    <v-icon color="white" right>{{ subitem.icon }}</v-icon>
                    <v-btn
                      class="text-none text-subtitle-1 ml-2"
                      variant="text"
                      color="white"
                      style="min-width: 120px"
                      >{{ subitem.text }}</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import LocalePicker from './LocalePicker/LocalePicker.vue'
import router from '@/router'
import { useUserStore } from '@/stores/user-auth'
import { useUserActions } from '@/stores/user-actions'
import { doctorAuthStore } from '@/stores/doctor-auth'

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    LocalePicker,
  },

  setup(_, { emit }) {
    const onLogoClicked = (): void => {
      router.push('/')
    }
    const { t } = useI18n()
    const userStore = useUserStore()
    const userAction = useUserActions()
    const docAuth = doctorAuthStore()
    const smallWindow = ref(window.innerWidth < 1000)

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    const headerProps = computed(() => {
      let header: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: t('header.home'),
          path: '/',
          subitems: null,
        },
        {
          text: t('header.aboutUs'),
          path: '/about-us',
          subitems: null,
        },
      ]

      let authHeader: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: t('header.auth-action-student'),
          path: null,
          subitems: [
            {
              text: t('header.login'),
              path: '/login',
              icon: 'mdi-login',
            },
            {
              text: t('header.register'),
              path: '/register',
              icon: 'mdi-account',
            },
          ],
        },
      ]

      let docAuthHeader: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: t('header.auth-action-doctor'),
          path: null,
          subitems: [
            {
              text: t('header.login'),
              path: '/doc-login',
              icon: 'mdi-login',
            },
            {
              text: t('header.register'),
              path: '/doc-register',
              icon: 'mdi-doctor',
            },
          ],
        },
      ]
      let docHeader: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: t('header.to-portal'),
          path: null,
          subitems: [
            {
              text: t('header.dashboard'),
              path: '/doctor-dashboard',
              icon: 'mdi-doctor',
            },
          ],
        },
      ]

      let profileHeader: {
        text: string
        path: string | null
        photo?: string | null
        subitems: { text: string; path: string; icon: string }[] | null
      }[] = [
        {
          text: '',
          path: null,
          photo:
            userAction.userProfileLink != '' &&
            userAction.userProfileLink != undefined
              ? userAction.userProfileLink
              : require('../../assets/blank.png'),
          subitems: [
            {
              text: t('header.profile'),
              path: '/profile/general-information',
              icon: 'mdi-account-plus',
            },
            {
              text: t('header.logout'),
              path: '/logout',
              icon: 'mdi-logout',
            },
          ],
        },
      ]

      docAuth.isAuthenticated
        ? (header = header.concat(docHeader))
        : (header = header.concat(docAuthHeader))

      !userStore.isAuthenticated
        ? (header = header.concat(authHeader))
        : (header = header.concat(profileHeader))

      return header
    })

    const isTouchScreen = computed(() => {
      return window.matchMedia('(pointer: coarse)').matches
    })

    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize)
    })

    return {
      onLogoClicked,
      smallWindow,
      isTouchScreen,
      headerProps,
    }
  },
})
</script>

<style scoped>
.app-bar {
  height: 70px;
  align-items: center;
  display: flex;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  margin: auto;
  background-color: #25344d;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.logo {
  height: 48px;
}

.text-logo {
  color: white;
  font-size: xx-large;
}
.btns-container {
  margin-left: auto;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .app-bar {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
