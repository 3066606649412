import { defineStore } from 'pinia'
import apiClient from './apiclients'
import { RequestResponse, SearchParams, Student } from './../types/index'
export const useUserActions = defineStore({
  id: 'user-actions',
  state: () => ({
    userProfileLink: '',
    name: '',
    email: '',
  }),

  actions: {
    async searchForDoctor(
      searchParams: SearchParams
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('public/search-doctor', searchParams)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async getSingleDoctor(
      id: string,
      params: object
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .get(`public/get-doctor/${id}`, params)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getStudentProfile(token: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }

      await apiClient
        .get('user/get-profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async isTokenValid(): Promise<boolean> {
      let isValid = false
      let url = ''
      let name = ''
      let email = ''
      const token = localStorage.getItem('accessToken')
      if (token) {
        await apiClient
          .get('user/get-profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            isValid = response.data.result
            url = response.data.result.profileUrl
            name =
              response.data.result.firstName +
              ' ' +
              response.data.result.lastName
            email = response.data.result.email
          })
          .catch(function (error) {
            isValid = error.response.data.success
            localStorage.removeItem('accessToken')
          })
        this.userProfileLink = url
        this.name = name
        this.email = email

        return isValid
      } else {
        return false
      }
    },
    async createPublicFile(
      linkObject: object,
      token: string
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('common/createPublic', linkObject, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async uploadFile(file: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [''] }
      const token = localStorage.getItem('accessToken') ?? ''
      const formData = new FormData()
      formData.append('file', file)
      await apiClient
        .post('/common/upload-file', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async updateStudentProfile(profile: object): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .post('user/update-profile', profile, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async getApplications(): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient('application', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async getApplication(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .get(`application/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async takeBackApplication(id: string): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .get(`application/${id}/revert-application`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async isDoctorAvailable(
      id: string,
      startDate: string,
      endDate: string
    ): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }

      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .get(
          `user/check-doc-available?doctorId=${id}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async applyToDoctor(application: object): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const token = localStorage.getItem('accessToken') ?? ''
      await apiClient
        .post('application', application, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async contactMedCircle(dataToSend: {
      name: string
      email: string
      message: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('public/contact-request', dataToSend)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    getDatesInString(data: Date): string {
      const year = data.getFullYear()
      const month = String(data.getMonth() + 1).padStart(2, '0')
      const day = String(data.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    async uploadImage(formData: FormData): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: [] }
      const token = localStorage.getItem('accessToken') ?? ''

      await apiClient
        .post('common/upload-file?type=profile', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
