<template>
  <request-status :response="requestResponse" :isLoading="loading" />

  <div align="start" justify="space-between" class="flex mb-10">
    <div class="flex-item">
      <v-card
        class="mx-auto mt-2"
        height="200"
        width="200"
        style="border-radius: 10%"
        :title="$t('applications.open')"
        align="center"
        @click="tab = 'CREATED'"
      >
        <v-img
          :src="require('../../../../assets/student5.png')"
          width="100"
          height="100"
        >
        </v-img>
        <v-card-title>
          {{ dashboardData?.applicants.CREATED ?? 0 }}
        </v-card-title></v-card
      >
    </div>
    <div class="flex-item">
      <v-card
        class="mx-auto mt-2"
        height="200"
        width="200"
        style="border-radius: 10%"
        :title="$t('applications.accepted')"
        align="center"
        @click="tab = 'ACCEPTED'"
      >
        <v-img
          :src="require('../../../../assets/student6.png')"
          width="100"
          height="100"
        >
        </v-img>
        <v-card-title>
          {{ dashboardData?.applicants.ACCEPTED ?? 0 }}
        </v-card-title>
      </v-card>
    </div>
    <div class="flex-item">
      <v-card
        class="mx-auto mt-2"
        height="200"
        width="200"
        style="border-radius: 10%"
        :title="$t('applications.rejected')"
        align="center"
        @click="tab = 'REJECTED'"
      >
        <v-img
          :src="require('../../../../assets/student7.png')"
          width="100"
          height="100"
        >
        </v-img>
        <v-card-title>
          {{ dashboardData?.applicants.REJECTED ?? 0 }}
        </v-card-title></v-card
      >
    </div>

    <div class="flex-item">
      <v-card
        class="mx-auto mt-2"
        height="200"
        width="200"
        style="border-radius: 10%"
        :title="$t('applications.all')"
        align="center"
        @click="tab = ''"
      >
        <v-img
          :src="require('../../../../assets/student8.png')"
          width="100"
          height="100"
        >
        </v-img>
        <v-card-title>
          {{
            (dashboardData?.applicants.ACCEPTED ?? 0) +
            (dashboardData?.applicants.REJECTED ?? 0) +
            (dashboardData?.applicants.CREATED ?? 0)
          }}
        </v-card-title></v-card
      >
    </div>
  </div>

  <v-card>
    <v-tabs v-model="tab" show-arrows bg-color="#25344d" fixed-tabs>
      <v-tab value="CREATED">{{ $t('applications.open') }}</v-tab>
      <v-tab value="ACCEPTED">
        {{ $t('applications.accepted') }}
      </v-tab>
      <v-tab value="REJECTED">
        {{ $t('applications.rejected') }}
      </v-tab>

      <v-tab value="">{{ $t('applications.all') }}</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="CREATED">
          <ApplicationsTable
            :tab="'CREATED'"
            @view-application="$emit('view-application', $event)"
          />
        </v-window-item>
        <v-window-item value="ACCEPTED">
          <ApplicationsTable
            :tab="'ACCEPTED'"
            @view-application="$emit('view-application', $event)"
          />
        </v-window-item>

        <v-window-item value="REJECTED">
          <ApplicationsTable
            :tab="'REJECTED'"
            @view-application="$emit('view-application', $event)"
          />
        </v-window-item>

        <v-window-item value="">
          <ApplicationsTable
            :tab="tab"
            @view-application="$emit('view-application', $event)"
          />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { defineProps, ref, onMounted, computed, Ref } from 'vue'
import { Applicant, DashboardData, RequestResponse } from '@/types'
import { doctorActions } from '@/stores/doctor-actions'
import ApplicationsTable from './ApplicationsTable/ApplicationsTable.vue'
import { watch } from 'vue'
const { t } = useI18n()

const docAction = doctorActions()
const props = defineProps<{ data: DashboardData | undefined }>()
const dashboardData = ref<DashboardData | undefined>(props.data)
const tab = ref<string>(
  docAction.studentTableTab !== '' ? docAction.studentTableTab : ''
)
const requestResponse = ref()
const loading = ref(false)

watch([tab], () => {
  docAction.studentTableTab = tab.value
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
