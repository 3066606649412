<template>
  <header-component></header-component>

  <!-- <p class="text text-center">MEDCIRCLE</p> -->
  <div align="center" class="image">
    <v-img :src="require('../../assets/main-logo.png')" width="300"></v-img>
  </div>
  <p class="sub-text text-center">{{ $t("landing-line") }}</p>
  <search-component style="margin-bottom: 150px"></search-component>

  <v-divider></v-divider>

  <advantage-disadvantage-component></advantage-disadvantage-component>
  <v-divider></v-divider>
  <CounterComponent></CounterComponent>
  <v-divider></v-divider>
  <top-doctors-component></top-doctors-component>

  <!-- <supported-component></supported-component> -->
  <footer-component></footer-component>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import HeaderComponent from "../Header/HeaderComponent.vue"
import SearchComponent from "../Search/SearchComponent.vue"
import FooterComponent from "../Footer/FooterComponent.vue"
import AdvantageDisadvantageComponent from "../Advantage-Disadvantage/Advantage-Disadvantage.vue"
import TopDoctorsComponent from "../Top-Doctors/Top-Doctors.vue"
import { useI18n } from "vue-i18n"
import { useUserActions } from "@/stores/user-actions"
import { useUserStore } from "@/stores/user-auth"
import SupportedComponent from "./SupportedPartners/SupportedPartners.vue"
import { useMeta } from "vue-meta"
import CounterComponent from "./Counter/CounterComponent.vue"

export default defineComponent({
  components: {
    HeaderComponent,
    SearchComponent,
    FooterComponent,
    AdvantageDisadvantageComponent,
    TopDoctorsComponent,
    CounterComponent,
  },
  name: "LandingComponent",

  setup() {
    onMounted(() => {
      localStorage.removeItem("searchParams")
      isTokenValid()
    })
    console.log("version-1.1")

    const smallWindow = ref(false)
    const isTokenValid = async (): Promise<void> => {
      const userAction = useUserActions()
      const response = await userAction.isTokenValid()
      updateWindowSize()
      if (!response) {
        localStorage.removeItem("accessToken")
      }
    }
    localStorage.removeItem("searchParams")

    const { t } = useI18n()
    useMeta(
      computed(() => ({
        title: t("landing-title"),
      }))
    )
    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1200
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      smallWindow,
    }
  },
})
</script>

<style>
.sub-text {
  font-size: 20px;
  font-weight: 300;
}
.image {
  margin-top: 0px;
}

.content {
  overflow-x: hidden;
}
</style>
