<template>
  <DoctorHeader></DoctorHeader>

  <v-card>
    <v-layout>
      <v-navigation-drawer
        :expand-on-hover="smallWindow"
        :rail="smallWindow"
        :permanent="!smallWindow"
        mobile-breakpoint="xs"
        width="230"
        height="100vh"
      >
        <v-list>
          <v-list-item
            :prepend-avatar="
              docAction.doctorProfileLink !== ''
                ? docAction.doctorProfileLink
                : require('../../../assets/blank.png')
            "
            :title="name"
          >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item
            prepend-icon="mdi-account"
            :title="$t('doctor.overview')"
            :class="{
              'v-list-item--active': selectedItem === 'Doctor Overview',
              'v-list-item--inactive': selectedItem !== 'Doctor Overview',
            }"
            @click="navigateTo('Doctor Overview')"
          >
          </v-list-item>

          <!-- <v-list-item
            prepend-icon="mdi-inbox-arrow-down"
            title="Inbox"
            stacked
            @click="selectedItem = 'construct'"
          >
            <template v-slot:append>
              <v-badge color="error" content="!" inline></v-badge>
            </template>
          </v-list-item> -->
          <v-list-item
            prepend-icon="mdi-radio-tower"
            title="Broadcast"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Broadcast',
              'v-list-item--inactive': selectedItem !== 'Broadcast',
            }"
            @click="navigateTo('Broadcast')"
          >
            <template v-slot:append>
              <v-badge color="blue" :content="$t('new')" inline></v-badge>
            </template>
          </v-list-item>

          <v-list-item
            prepend-icon="mdi-account-group"
            :title="$t('your-circle')"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Subscribers',
              'v-list-item--inactive': selectedItem !== 'Subscribers',
            }"
            @click="navigateTo('Subscribers')"
            ><template v-slot:append>
              <v-badge color="blue" :content="$t('new')" inline></v-badge>
            </template>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main style="min-height: 100vh">
        <router-view />
      </v-main>
    </v-layout>
  </v-card>
  <FooterComponent></FooterComponent>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue'

import { useI18n } from 'vue-i18n'
import DoctorHeader from '../Headers/DoctorHeader.vue'
import { doctorActions } from '@/stores/doctor-actions'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import { useMeta } from 'vue-meta'
import router from '@/router'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    DoctorHeader,
    FooterComponent,
  },
  name: 'DoctorDashboard',

  setup() {
    const { t } = useI18n()
    const selectedItem = ref()
    const docProfileLink = ref('')
    const name = ref('')
    const drawer = ref(true)
    const rail = ref(true)
    const applicationId = ref<string>()
    const smallWindow = ref(window.innerWidth < 600)
    const docAction = doctorActions()
    const route = useRoute()

    onMounted(() => {
      router.push({ name: routeName.value ?? 'Doctor Overview' })
      selectedItem.value = routeName.value ?? 'Doctor Overview'
      docProfileLink.value = docAction.doctorProfileLink
      name.value = docAction.docName
    })

    const routeName = computed(() => {
      return route.name
    })

    useMeta(
      computed(() => ({
        title: name.value ?? 'Medcircle',
      }))
    )

    watch([routeName], () => {
      selectedItem.value = routeName.value ?? 'Doctor Overview'
    })

    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize)
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    return {
      selectedItem,
      docProfileLink,
      name,
      drawer,
      rail,
      smallWindow,
      applicationId,
      routeName,
      docAction,
    }
  },
  methods: {
    navigateTo(item: string) {
      this.selectedItem = item
      this.$router.push({ name: item })
    },
  },
})
</script>

<style>
.text {
  font-size: 50px;
  font-weight: 500;
  margin-top: 130px;
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}

.content {
  overflow-x: hidden;
}
.footer {
  margin-top: 200px;
}
</style>
