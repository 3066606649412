import { useUserActions } from '@/stores/user-actions'
import { doctorActions } from '../stores/doctor-actions'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export const isDoctorGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const docAction = doctorActions()
  const isAuthenticated = await docAction.isTokenValid()
  if (isAuthenticated) {
    next()
  } else {
    next({ path: '/doc-login' })
  }
}

export const isLoggedInDoctorGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const docAction = doctorActions()
  const isAuthenticated = await docAction.isTokenValid()
  if (isAuthenticated) {
    next({ path: '/doctor-dashboard' })
  } else {
    next()
  }
}

export const isStudentGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const userAction = useUserActions()
  const isAuthenticated = await userAction.isTokenValid()
  if (isAuthenticated) {
    next()
  } else {
    next({ path: '/login' })
  }
}
