<template>
  <div class="container">
    <HeaderComponent />
    <request-status :response="responseStatus" :isLoading="false" />

    <v-card
      class="mx-auto pa-12 pb-8 my-15"
      elevation="8"
      max-width="548"
      rounded="lg"
    >
      <div v-if="!responseStatus.success">
        <div class="text-subtitle-1 text-medium-emphasis">
          {{ $t('account') }}
        </div>

        <v-text-field
          v-model="email"
          density="compact"
          :placeholder="$t('email')"
          prepend-inner-icon="mdi-email-outline"
          :rules="emailRules"
          variant="outlined"
          @keyup.enter="isInfoCompleted ? sendLink() : undefined"
        ></v-text-field>

        <v-btn
          class="mb-8"
          color="blue"
          size="large"
          variant="flat"
          block
          :loading="isLoading"
          :disabled="!isInfoCompleted"
          @click="sendLink"
        >
          {{ $t('reset-password') }}
        </v-btn>

        <v-card-text class="text-center">
          <a
            class="text-blue text-decoration-none"
            @click="router.push('/login')"
            style="cursor: pointer"
          >
            {{ $t('back-login') }}<v-icon icon="mdi-chevron-right"></v-icon>
          </a>
        </v-card-text>
      </div>

      <div v-else>
        <v-card-item style="font-family: Verdana, Geneva, Tahoma, sans-serif">
          <v-card-title>{{ $t('forgot-password-title') }} </v-card-title>
          <v-card-text> {{ $t('forgot-password-text') }} </v-card-text>
        </v-card-item>
      </div>
    </v-card>
  </div>
  <FooterComponent></FooterComponent>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { doctorAuthStore } from '../../../../stores/doctor-auth'
import { useI18n } from 'vue-i18n'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import router from '@/router'
import { useMeta } from 'vue-meta'
import FooterComponent from '@/components/Footer/FooterComponent.vue'

const { t } = useI18n()
const email = ref<string>('')
const isLoading = ref(false)
const responseStatus = ref({})

const emailRules = [
  (v: string) => !!v || t('email-required'),
  (v: string) => /.+@.+\..+/.test(v) || t('email-authentic'),
]

const isInfoCompleted = computed(() => {
  const isEmailInvalid = emailRules.some((rule) => {
    const result = rule(email.value)
    return typeof result === 'string'
  })

  return !isEmailInvalid
})
useMeta(
  computed(() => ({
    title: 'Medcircle',
  }))
)

const sendLink = async () => {
  isLoading.value = true
  const docAuth = doctorAuthStore()

  const credentials = {
    email: email.value,
  }
  const response = await docAuth.forgotPassword(credentials)
  responseStatus.value = response
  isLoading.value = false
}
</script>
<style>
.container {
  min-height: 77vh;
}
</style>
