import { RequestResponse } from './../types/index'
import { defineStore } from 'pinia'
import apiClient from './apiclients'
import { doctorActions } from './doctor-actions'

export const doctorAuthStore = defineStore({
  id: 'doctor',

  state: () => ({
    docAccessToken: localStorage.getItem('docAccessToken') ?? '',
    docId: localStorage.getItem('docId') ?? '',
    isAuthenticated: false,
    loadAuth: isAuthenticated(),
  }),

  actions: {
    async login(credentials: {
      email: string
      password: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('doctor/login', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })

      return data
    },
    async register(doctor: {
      title: string
      firstName: string
      lastName: string
      email: string
      password: string
      agreements: boolean
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('doctor/register', doctor)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async forgotPassword(credentials: {
      email: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      await apiClient
        .post('doctor/forgot-password', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
    async resetPassword(credentials: {
      userId: string
      token: string
      password: string
    }): Promise<RequestResponse> {
      let data: RequestResponse = { success: false, result: null }
      const docToken = localStorage.getItem('docAccessToken') ?? ''

      await apiClient
        .post('doctor/forgot-password-verify', credentials)
        .then(function (response) {
          data = response.data
        })
        .catch(function (error) {
          data = error.response.data
        })
      return data
    },
  },
})
async function isAuthenticated(): Promise<boolean> {
  const docAction = doctorActions()
  const docAuth = doctorAuthStore()
  const isValid = await docAction.isTokenValid()

  if (!isValid) {
    localStorage.removeItem('docAccessToken')
    docAuth.isAuthenticated = false
  } else {
    docAuth.isAuthenticated = true
  }
  return true
}
