import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  align: "center",
  class: "image"
}
const _hoisted_2 = { class: "sub-text text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_search_component = _resolveComponent("search-component")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_advantage_disadvantage_component = _resolveComponent("advantage-disadvantage-component")!
  const _component_CounterComponent = _resolveComponent("CounterComponent")!
  const _component_top_doctors_component = _resolveComponent("top-doctors-component")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_component),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_img, {
        src: require('../../assets/main-logo.png'),
        width: "300"
      }, null, 8, ["src"])
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("landing-line")), 1),
    _createVNode(_component_search_component, { style: {"margin-bottom":"150px"} }),
    _createVNode(_component_v_divider),
    _createVNode(_component_advantage_disadvantage_component),
    _createVNode(_component_v_divider),
    _createVNode(_component_CounterComponent),
    _createVNode(_component_v_divider),
    _createVNode(_component_top_doctors_component),
    _createVNode(_component_footer_component)
  ], 64))
}