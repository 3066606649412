<template>
  <request-status :response="requestResponse" :isLoading="isLoading" />
  <h2 v-if="isStudent" class="mt-4">
    {{ $t('your-application') }} {{ docName }}
  </h2>
  <v-divider v-if="isStudent" class="mt-3"></v-divider>
  <v-row>
    <v-col :cols="smallWindow ? 12 : 3">
      <div align="center">
        <div class="flex-item">
          <div class="mt-5">
            <v-img
              class="ml-7"
              :width="100"
              style="border-radius: 50%"
              cover
              :src="
                application?.profileUrl !== ''
                  ? application?.profileUrl
                  : require('../../../assets/blank.png')
              "
            ></v-img>
          </div>
          <div class="mt-5">
            <v-btn
              :loading="isLoading"
              :color="cvUrl ? 'primary' : 'red'"
              width="130"
              class="ml-5 mb-2"
              :href="cvUrl ?? ''"
              target="_blank"
              >{{ $t('resume') }}</v-btn
            >
          </div>
        </div>

        <div class="flex-item">
          <div class="mt-5">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn
                  :loading="isLoading"
                  v-bind="props"
                  :color="ibUrl ? 'primary' : 'red'"
                  class="ml-5 mb-2"
                  :href="ibUrl ?? ''"
                  target="_blank"
                  width="130"
                >
                  {{ $t('ib-short') }}</v-btn
                >
              </template>
              {{ $t('ib') }}
            </v-tooltip>
          </div>
        </div>
        <div class="flex-item">
          <div class="mt-5">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn
                  :loading="isLoading"
                  v-bind="props"
                  :color="motivationUrl ? 'primary' : 'red'"
                  width="130"
                  class="ml-5 mb-2"
                  :href="motivationUrl"
                  target="_blank"
                >
                  {{ $t('motivation') }}</v-btn
                >
              </template>
              {{ $t('tip-motivation') }}
            </v-tooltip>
          </div>
        </div>
        <div class="flex-item mt-5" align="center">
          <p class="font-weight-medium">{{ $t('application-status') }}:</p>
          <p>
            {{ statusMapping?.[application?.status] }}
          </p>

          <div align="center" class="mt-5">
            <v-img
              v-if="application?.status == 'ACCEPTED'"
              width="100px"
              :src="require('../../../assets/happy.png')"
            ></v-img>
            <v-img
              v-if="application?.status == 'REJECTED'"
              width="100px"
              :src="require('../../../assets/sad.png')"
            ></v-img>
          </div>
        </div>
      </div>
    </v-col>
    <v-col :cols="smallWindow ? 12 : 9">
      <div class="flex flex-wrap mt-10">
        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('gender') }}
          </div>

          <v-text-field
            class="ml-2"
            density="compact"
            :model-value="application?.gender"
            readonly
            :placeholder="$t('gender')"
            prepend-inner-icon="mdi-gender-male-female"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>

        <div class="flex-item" justify="start">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('first-name') }}
          </div>

          <v-text-field
            class="ml-2"
            density="compact"
            :model-value="application?.firstName"
            readonly
            :placeholder="$t('first-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('last-name') }}
          </div>
          <v-text-field
            class="ml-2"
            density="compact"
            :model-value="application?.lastName"
            readonly
            :placeholder="$t('last-name')"
            prepend-inner-icon="mdi-pencil"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('email') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.userEmail"
            readonly
            density="compact"
            :placeholder="$t('email')"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('birthday') }}
          </div>
          <v-text-field
            class="ml-2"
            density="compact"
            variant="outlined"
            readonly
            :model-value="dateOfBirth"
            prepend-inner-icon="mdi-calendar"
            style="min-width: 200px"
          >
          </v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('phoneNumber') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.phoneNo"
            readonly
            density="compact"
            :placeholder="$t('phoneNumber')"
            prepend-inner-icon="mdi-cellphone"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('street') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.street"
            readonly
            density="compact"
            :placeholder="$t('street')"
            prepend-inner-icon="mdi-map-marker"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('post') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.zip"
            readonly
            density="compact"
            :placeholder="$t('post')"
            prepend-inner-icon="mdi-numeric"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('city') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.location"
            readonly
            density="compact"
            :placeholder="$t('city')"
            prepend-inner-icon="mdi-city"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('state') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.state"
            readonly
            density="compact"
            :placeholder="$t('state')"
            prepend-inner-icon="mdi-map-marker"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('country') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.land"
            readonly
            density="compact"
            :placeholder="$t('country')"
            prepend-inner-icon="mdi-earth"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('university') }}
          </div>
          <v-text-field
            class="ml-2"
            :model-value="application?.university"
            readonly
            density="compact"
            :placeholder="$t('university')"
            prepend-inner-icon="mdi-school"
            variant="outlined"
            style="min-width: 200px"
          ></v-text-field>
        </div>
        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('applied-on') }}
          </div>
          <v-text-field
            class="ml-2"
            density="compact"
            variant="outlined"
            readonly
            :model-value="appliedOn"
            prepend-inner-icon="mdi-calendar"
            style="min-width: 200px"
          >
          </v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('table.start-date') }}
          </div>
          <v-text-field
            class="ml-2"
            density="compact"
            variant="outlined"
            readonly
            :model-value="startDate"
            prepend-inner-icon="mdi-calendar"
            style="min-width: 200px"
          >
          </v-text-field>
        </div>

        <div class="flex-item">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('table.end-date') }}
          </div>
          <v-text-field
            class="ml-2"
            density="compact"
            variant="outlined"
            readonly
            :model-value="endDate"
            prepend-inner-icon="mdi-calendar"
            style="min-width: 200px"
          >
          </v-text-field>
        </div>

        <div class="flex-item" v-if="singleApplication?.comment">
          <div class="d-flex text-subtitle-1 text-medium-emphasis ml-2">
            {{ $t('action.comment') }}
          </div>
          <v-textarea
            class="ml-2"
            :model-value="singleApplication?.comment"
            readonly
            row-height="25"
            rows="3"
            density="compact"
            :placeholder="$t('action.comment')"
            prepend-inner-icon="mdi-comment"
            variant="outlined"
            style="min-width: 200px"
          ></v-textarea>
        </div>

        <div
          class="flex-item"
          v-if="isStudent && application?.status == 'CREATED'"
        >
          <v-dialog v-model="takeBackDialog" max-width="500" persistent>
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn v-bind="activatorProps" color="#3fb984" class="mr-4 mb-5">
                {{ $t('take-back') }}
              </v-btn>
            </template>

            <v-card
              prepend-icon="mdi-cancel"
              :text="$t('action.take-back-text')"
              :title="$t('action.take-back-title')"
            >
              <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-btn @click="takeBack()">{{ $t('go-back') }}</v-btn>
                <v-btn @click="takeBack(application?._id)">{{
                  $t('take-back')
                }}</v-btn>
              </template>
            </v-card>
          </v-dialog>
        </div>

        <div v-else class="flex-item">
          <div
            align="center"
            v-if="
              !singleApplication?.comment &&
              !isStudent &&
              application?.status == 'CREATED'
            "
          >
            <v-dialog v-model="acceptDialog" max-width="500">
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                  v-bind="activatorProps"
                  color="#3fb984"
                  class="mr-4 mb-5"
                >
                  {{ $t('action.accept') }}
                </v-btn>
              </template>

              <v-card
                prepend-icon="mdi-check"
                :text="$t('action.accept-application-text')"
                :title="$t('action.accept-application')"
              >
                <v-textarea
                  v-model="comments"
                  class="ml-5 mr-5"
                  :label="$t('action.comment')"
                  row-height="25"
                  rows="3"
                  density="compact"
                  variant="outlined"
                  prepend-inner-icon="mdi-comment"
                ></v-textarea>
                <template v-slot:actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="updateStatus('', '')">{{
                    $t('go-back')
                  }}</v-btn>
                  <v-btn @click="updateStatus(application?._id, 'ACCEPTED')">{{
                    $t('action.accept')
                  }}</v-btn>
                </template>
              </v-card>
            </v-dialog>
            <v-dialog v-model="rejectDialog" max-width="500">
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn v-bind="activatorProps" color="red" class="ml-4 mb-5">
                  {{ $t('action.reject') }}
                </v-btn>
              </template>

              <v-card
                prepend-icon="mdi-undo"
                :text="$t('action.reject-application-text')"
                :title="$t('action.reject-application')"
              >
                <v-textarea
                  v-model="comments"
                  class="ml-5 mr-5"
                  :label="$t('action.comment')"
                  row-height="25"
                  rows="3"
                  density="compact"
                  variant="outlined"
                  prepend-inner-icon="mdi-comment"
                ></v-textarea>
                <template v-slot:actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="updateStatus('', '')">{{
                    $t('go-back')
                  }}</v-btn>
                  <v-btn @click="updateStatus(application?._id, 'REJECTED')">{{
                    $t('action.reject')
                  }}</v-btn>
                </template>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserActions } from '@/stores/user-actions'
import { FormatDateFunction } from '@/types/spec'
import { useRoute } from 'vue-router'
import { Applicant } from '@/types'
import { doctorActions } from '@/stores/doctor-actions'
export default defineComponent({
  name: 'SingleApplication',

  props: {
    singleApplication: {
      type: Object as () => Applicant,
    },
    isStudent: {
      type: Boolean,
    },
  },

  setup(props) {
    const { t } = useI18n()
    const userAction = useUserActions()
    const requestResponse = ref({})
    const application = ref()
    const id = ref()
    const route = useRoute()
    const isLoading = ref(true)
    const smallWindow = ref(window.innerWidth < 1000)
    const takingBack = ref(false)
    const takeBackDialog = ref(false)
    const rejectDialog = ref(false)
    const acceptDialog = ref(false)
    const cvUrl = ref<string | null>(null)
    const ibUrl = ref<string | null>(null)
    const motivationUrl = ref<string | undefined>(undefined)
    const startDate = ref<string>('')
    const endDate = ref<string>('')
    const dateOfBirth = ref<string>('')
    const appliedOn = ref<string>('')
    const comments = ref<string>('')
    const status = ref()
    const statusMapping = ref({
      CREATED: '',
      REVERT: '',
      REJECTED: '',
      ACCEPTED: '',
    })
    onMounted(async () => {
      application.value = props.singleApplication
      startDate.value = FormatDateFunction(
        props.singleApplication?.startDate ?? ''
      )
      endDate.value = FormatDateFunction(props.singleApplication?.endDate ?? '')
      dateOfBirth.value = FormatDateFunction(
        props.singleApplication?.dateOfBirth ?? ''
      )
      appliedOn.value = FormatDateFunction(
        props.singleApplication?.createdAt ?? ''
      )

      buildApplication()
      mapStatus()
    })

    const buildApplication = async () => {
      if (application.value?.cvUrl) {
        link(application.value?.cvUrl).then((val) => {
          cvUrl.value = val
        })
      }
      if (application.value?.immatriculationUrl) {
        link(application.value?.immatriculationUrl).then((val) => {
          ibUrl.value = val
        })
      }
      if (application.value?.motivationUrl) {
        link(application.value?.motivationUrl).then((val) => {
          motivationUrl.value = val
        })
      }
      isLoading.value = false
    }

    const link = async (link: string): Promise<string> => {
      isLoading.value = true
      const linkObject = {
        fileUri: link,
      }
      const studentToken = localStorage.getItem('accessToken')
      const docToken = localStorage.getItem('docAccessToken')
      const response = await userAction.createPublicFile(
        linkObject,
        studentToken ?? docToken ?? ''
      )
      isLoading.value = false

      requestResponse.value = response

      if (typeof response.result === 'string') {
        return response.result
      }
      return ''
    }

    const docName = computed(() => {
      return `${props.singleApplication?.docInfo.title} ${props.singleApplication?.docInfo.firstName} ${props.singleApplication?.docInfo.lastName}`
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
      window.addEventListener('locale-changed', mapStatus)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowSize)
      window.removeEventListener('locale-changed', mapStatus)
    })

    const mapStatus = () => {
      if (props.isStudent) {
        statusMapping.value.CREATED = t('status.open')
        statusMapping.value.REVERT = t('status.back')
        statusMapping.value.REJECTED = t('status.rejected')
        statusMapping.value.ACCEPTED = t('status.accepted')
      } else {
        statusMapping.value.CREATED = t('status.doc.open')
        statusMapping.value.REVERT = t('status.doc.back')
        statusMapping.value.REJECTED = t('status.doc.rejected')
        statusMapping.value.ACCEPTED = t('status.doc.accepted')
      }
    }

    return {
      requestResponse,
      isLoading,
      application,
      statusMapping,
      takingBack,
      appliedOn,
      rejectDialog,
      acceptDialog,
      t,
      takeBackDialog,
      status,
      startDate,
      endDate,
      smallWindow,
      cvUrl,
      ibUrl,
      motivationUrl,
      dateOfBirth,
      comments,
      docName,
    }
  },
  methods: {
    async updateStatus(id: string, status: string): Promise<void> {
      const docAction = doctorActions()
      this.acceptDialog = false
      this.rejectDialog = false
      if (id != '') {
        this.isLoading = true
        const body = {
          status: status,
          comment: this.comments,
          id: id,
        }
        const response = await docAction.updateApplicationStatus(body)

        if (response.success) {
          this.$toast.success(this.$t('application-updated-successful'), {
            position: 'top-right',
          })
        } else {
          this.$toast.error(this.$t('application-updated-not-successful'), {
            position: 'top-right',
          })
        }
        this.isLoading = false
        this.$router.push({ name: 'Doctor Overview' })
      }
    },
    async takeBack(id?: string): Promise<void> {
      const userAction = useUserActions()
      this.takeBackDialog = false

      if (id) {
        this.isLoading = true

        const response = await userAction.takeBackApplication(id)

        if (response.success) {
          this.$toast.success(this.$t('status.back'), {
            position: 'top-right',
          })
          this.$emit('action-taken')
        } else {
          this.$toast.error(this.$t('application-updated-not-successful'), {
            position: 'top-right',
          })
        }
        this.isLoading = false
      }
    },
  },
})
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 30%; /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the spacing between items as needed */
}
.pointer {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
}
</style>
