<template>
  <div class="">
    <h1 class="mt-10" align="center">{{ $t('sofar') }}</h1>
    <div class="flex mb-10 mt-10">
      <div class="flex-item">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              class="ml-5 mr-5 mb-5"
              align="center"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-row class="mt-5 mb-10" align="center">
                <v-col cols="5">
                  <v-img
                    class="ml-10"
                    height="150px"
                    :src="require('../../../assets/doctor1.png')"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div
                    class="mr-10 ml-5"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <span
                      style="font-size: 80px; color: gray; font-weight: 600"
                    >
                      50+
                    </span>
                    <span
                      style="font-size: 30px; color: gray; font-weight: 600"
                    >
                      {{ t('doctors') }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card>
          </template>
        </v-hover>
      </div>
      <div class="flex-item">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              class="ml-5 mr-5 mb-5"
              align="center"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-row class="mt-5 mb-10" align="center">
                <v-col cols="5">
                  <v-img
                    class="ml-10"
                    height="150px"
                    :src="require('../../../assets/graduated.png')"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div
                    class="mr-10 ml-5"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <span
                      style="font-size: 80px; color: gray; font-weight: 600"
                    >
                      100+
                    </span>
                    <span
                      style="font-size: 30px; color: gray; font-weight: 600"
                    >
                      {{ t('students') }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card>
          </template>
        </v-hover>
      </div>

      <!-- <div class="flex-item">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              class="ml-5 mr-5 mb-5"
              align="center"
              min-width="420"
              :elevation="isHovering ? 16 : 6"
              v-bind="props"
            >
              <v-row class="mt-5 mb-10" align="center">
                <v-col cols="5">
                  <v-img
                    class="ml-10"
                    height="150px"
                    :src="require('../../../assets/hospital.png')"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div
                    class="mr-10 ml-5"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <p style="font-size: 80px; color: gray; font-weight: 600">
                      0+
                    </p>
                    <p style="font-size: 30px; color: gray; font-weight: 600">
                      {{ t('hospitals') }}
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card>
          </template>
        </v-hover>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CounterComponent',

  data() {
    const { t } = useI18n()

    return { t }
  },
})
</script>
<style>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 25%; /* Adjust the width as needed */
}
</style>
