import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10" }
const _hoisted_2 = { class: "footer-container" }
const _hoisted_3 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_divider),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_v_btn, {
          rounded: "sm",
          variant: "text",
          class: "mr-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/t-and-c')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('footer.terms')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          rounded: "sm",
          variant: "text",
          class: "mr-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/contact')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('footer.contact')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          rounded: "sm",
          variant: "text",
          class: "mr-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/imprint')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('footer.imprint')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          rounded: "sm",
          variant: "text",
          class: "mr-2",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/privacy-policy')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('footer.privacy-policy')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          rounded: "sm",
          variant: "text",
          class: "mr-2",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/attribute')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('footer.attribute')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_btn, {
          icon: "mdi-linkedin",
          size: "small",
          href: "https://www.linkedin.com/company/medcircle-eu/",
          target: "_blank"
        })
      ])
    ])
  ]))
}